import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status == 401) console.error('Unauthorized request:', err);
        else if (err.status > 400) console.error('Client side error: ', err);
        else if (err.status >= 500) console.error('Server side error: ', err);
        else console.error('HTTP error:', err);
      } else console.error('An error occurred: ', err);

      let e = err as any;
      if (e.error.errors && checkForUnauthorizedError(e.error.errors))
        window.location.href = '/login';

      return throwError(() => err?.error?.errors?.[0]);
    }),
  );
};

function checkForUnauthorizedError(errs: any[]) {
  for (let err of errs)
    if (err.extensions.code === 'AUTH_NOT_AUTHORIZED') return true;

  return false;
}

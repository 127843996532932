import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../environments/environment';

const uri = environment.apiUrl;
const wssUri = environment.wssUrl;
var connectionParams = {
  authorization: '',
};

function createApollo(httpLink: HttpLink) {
  const middleware = new ApolloLink((request, forward) => {
    let token: string;
    let storedUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    token = storedUser?.authToken;

    connectionParams.authorization = `Bearer ${token}`;
    return forward(request);
  });

  let httpsLink = middleware.concat(httpLink.create({ uri }));

  let link = httpsLink;
  // Wss link
  // TODO new protocol
  // const wsLink = new WebSocketLink({
  //   uri: wssUri,
  //   options: {
  //     lazy: true,
  //     reconnect: true,
  //     reconnectionAttempts: 100,
  //     connectionParams,
  //   },
  // });

  // const link = split(
  //   ({ query }) => {
  //     const definition = getMainDefinition(query);
  //     return (
  //       definition.kind === 'OperationDefinition' &&
  //       definition.operation === 'subscription'
  //     );
  //   },
  //   wsLink,
  //   httpsLink,
  // );

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          trainers: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return incoming;
            },
          },
          clubs: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return incoming;
            },
          },
          teams: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return incoming;
            },
          },
          translationsByKey: {
            keyArgs: ['key'],
            merge(existing = [], incoming) {
              return incoming;
            },
          },
          translations: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return incoming;
            },
          },
          clubMembers: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return incoming;
            },
          },
        },
      },
    },
  });

  //Enable apollo extension for debbug in dev and staging environment
  return environment.production
    ? { link, cache, name: 'MatchAmigo', version: '1.0.0' }
    : {
        link,
        cache,
        name: 'MatchAmigo',
        version: '1.0.0',
        connectToDevTools: true,
      };
}

export const provideApollo = () => [
  {
    provide: APOLLO_OPTIONS,
    useFactory: createApollo,
    deps: [HttpLink],
  },
  Apollo,
];

import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status == 401) {
          // Specific handling for unauthorized errors         
          console.error('Unauthorized request:', err);
          // TODO implement relogin here
        } else if (err.status > 400)
          console.error('Client side error: ', err)
        else if (err.status >= 500)
          console.error('Server side error: ', err)
        else
          console.error('HTTP error:', err);
      } else {
        console.error('An error occurred: ', err);
      }
      return throwError(() => err?.error?.errors?.[0]);
    })
  );
};

import { Routes } from '@angular/router';
import { LoginGuard } from './core/guards/login.guard';
import { APP_TITLE } from './core/utils/default-variables';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        loadComponent: () =>
          import('./auth/pages/login/login.component').then(
            (c) => c.LoginComponent,
          ),
        title: APP_TITLE + ': Login',
      },
      {
        path: 'signup',
        loadComponent: () =>
          import('./auth/pages/register/register.component').then(
            (c) => c.RegisterComponent,
          ),
        title: APP_TITLE + ': Signup',
      },
      {
        path: 'verify/:token/:userId',
        loadComponent: () =>
          import('./auth/pages/verify-email/verify-email.component').then(
            (c) => c.VerifyEmailComponent,
          ),
        title: APP_TITLE + ': Account verificatio',
      },
      {
        path: 'start-reset-password',
        loadComponent: () =>
          import(
            './auth/pages/inititate-reset-password/inititate-reset-password.component'
          ).then((c) => c.InititateResetPasswordComponent),
        title: APP_TITLE + ': Reset password',
      },
      {
        path: 'resetPassword/:token/:email',
        loadComponent: () =>
          import('./auth/pages/reset-password/reset-password.component').then(
            (c) => c.ResetPasswordComponent,
          ),
        title: APP_TITLE + ': Reset password',
      },
      {
        path: 'accept-invite-register/:token',
        loadComponent: () =>
          import(
            './auth/pages/accept-invite-register/accept-invite-register.component'
          ).then((c) => c.AcceptInviteRegisterComponent),
        title: APP_TITLE + ': Registe',
      },
      {
        path: 'accept-invite/:token',
        loadComponent: () =>
          import('./auth/pages/accept-invite/accept-invite.component').then(
            (c) => c.AcceptInviteComponent,
          ),
        title: APP_TITLE + ': Invitation',
      },
    ],
  },
  {
    path: 'live-results/:id',
    loadComponent: () =>
      import(
        './features/tournaments/pages/live-results/live-results.component'
      ).then((c) => c.LiveResultsComponent),
    title: `${APP_TITLE} :Results`,
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/feature.routes').then((r) => r.featureRoutes),
    // canActivate: [LoginGuard],
  },
  {
    path: 'clubs',
    loadChildren: () =>
      import('./features/clubs/club.routes').then((r) => r.routes),
    title: `${APP_TITLE} :Clubs`,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

import { ErrorHandler, Injectable, NgZone } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any) {
    console.log('%c', 'color:red', error);

    if (
      error?.message?.includes('fetch dynamically imported module') ||
      error?.message?.includes('chunk')
    ) {
      console.warn('Detected missing chunk. Reloading...');
      localStorage.setItem('force-reload', 'true');
      location.reload();
    }
    console.error('Global error:', error);
  }
}

import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { AuthService } from './auth/services/auth.service';
import { TranslationService } from './features/translations/services/translation.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'Match Amigo';
  private transService = inject(TranslationService);
  private auth = inject(AuthService);

  constructor() {
    let language: string = this.auth.user()?.language?.isoCode2 || 'SR';
    this.transService.getTranslations(language);
  }
}
